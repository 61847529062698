<template>
  <div
    :style="isDragging && 'border-color: green;'"
    class="dropzone"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div v-if="this.files?.length === 0" class="label-upload">
      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Для загрузки перетащите файлы</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>
    </div>

    <div v-else>
      {{ this.formatedFiles }}
    </div>

    <input
      ref="file"
      :multiple="multiple"
      type="file"
      id="fileInput"
      class="hidden-input"
      @change="onChange"
      accept=".jpg, .jpeg, .png"
    />
  </div>
</template>

<script>
// import JwtService from '@/core/services/jwt.service';
import {
  GET_UPLOAD_TOKEN,
  UPLOAD_SINGLE_FILE,
} from '@/core/services/store/uploads.module';

export default {
  props: {
    value: {
      type: [Array, Object, String],
      default: () => [],
    },
    options: {
      type: Object,
      default: () => null,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      isDragging: false,
      localValue: this.value,
      files: [],
      formatedFiles: this.multiple ? [] : null,
    };
  },

  beforeMount() {
    this.$store.dispatch(GET_UPLOAD_TOKEN);
  },

  watch: {
    async files() {
      this.formatedFiles = await this.fill();
    },
  },

  methods: {
    async fill() {
      let content = null;
      if (this.files?.length > 0) {
        if (this.multiple) {
          return this.files.map(async (item) => {
            content = await this.getBase64(item);
            return {
              meta: {
                extension: /[.]/.exec(item.name)
                  ? '.' + /[^.]+$/.exec(item.name)?.[0]
                  : undefined,
                'content-type': item.type,
              },
              content,
            };
          });
        } else {
          content = await this.getBase64(this.files[0]);
          const data = {
            meta: {
              extension: /[.]/.exec(this.files[0].name)
                ? '.' + /[^.]+$/.exec(this.files[0].name)?.[0]
                : undefined,
              'content-type': this.files[0].type,
            },
            content,
          };

          await this.$store.dispatch(UPLOAD_SINGLE_FILE, data);
        }
      }
    },
    onChange() {
      if (!this.multiple) {
        this.files.push(...this.$refs.file.files);
      }
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },

    async getBase64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
      return result_base64;
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  padding: 20px;
  border: 2px solid #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  background-color: #f6f6f6;
  height: 100px;

  .label-upload {
    text-align: center;
  }

  .hidden-input {
    display: none;
  }
}
</style>
