<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab :disabled="!isExists">
        <v-badge
          v-if="form.items.length !== 0"
          :content="form.items.length"
          color="red"
        >
          Дочерние блоки
        </v-badge>

        <span v-else> Дочерние блоки </span>
      </v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <!--<b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>-->
          <b-form>
            <b-form-group
              id="input-group-2"
              label="Название блока"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Название блока"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-type"
              label="Тип блока"
              label-for="input-type"
            >
              <b-form-select
                v-model="form.type_block_oid"
                :options="typeBlocksOptions"
                id="input-type"
                :disabled="isExists"
              />
            </b-form-group>

            <b-form-group
              id="input-group-audience"
              label="Аудитория"
              label-for="input-audience"
            >
              <vue-select
                v-model="form.audience_oid"
                :options="audiencesOptions"
                id="input-audience"
                :settings="{
                  multiple: true,
                  selectionCssClass: 'select-container',
                }"
              />
            </b-form-group>

            <b-form-group id="input-group-7">
              <switch-checkbox v-model="form.is_active" label="Активен" />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
      <v-tab-item>
        <dashboard-parent-list :items="form.items" :parentOid="form.oid" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';

export default {
  components: { SwitchCheckbox },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
    };
  },

  computed: {
    ...mapGetters(['audiences', 'typeBlocks']),
    audiencesOptions() {
      return this.audiences.map((x) => {
        return { text: x.title, id: x.oid };
      });
    },

    typeBlocksOptions() {
      return this.typeBlocks.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },

    isExists() {
      return Boolean(this?.form?.oid);
    },
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: this.isExists ? 'Сохранено!' : 'Создано!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
