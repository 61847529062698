<template>
  <div class="auto-dealer-table">
    <v-data-table
      v-sortable-data-table
      :headers="headers"
      :items="localItems"
      item-key="name"
      :item-class="itemClass"
      disable-pagination
      class="elevation-1 row-links"
      hide-default-footer
      @sorted="saveOrder"
    >
      <!--<template v-slot:body.append="{ headers }">
        <tr>
          <td :colspan="headers.length">
            <div class="d-flex justify-content-end">
              <b-button variant="success">Создать</b-button>
            </div>
          </td>
        </tr>
      </template>-->
      <template #item.is_active="{ item }">
        <yes-no-badge :active="item.is_active" />
      </template>

      <template #item.oid="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.oid } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.oid)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <dashboard-parent-form v-model="editItem" ref="dashboardParentForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import YesNoBadge from '@/view/content/YesNoBadge';
import {
  GET_ITEM,
  SET_ITEMS_SORT,
} from '@/core/services/store/dashboard.module';
import Sortable from 'sortablejs';

export default {
  components: { YesNoBadge },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          filter: '.filtered',
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      localItems: this.items,
      headers: [
        { text: 'Название', value: 'name', sortable: false },
        {
          text: 'Тип блока',
          value: 'type',
          sortable: false,
        },
        { text: 'Аудитория', value: 'audience', sortable: false },
        {
          text: ' Активен',
          value: 'is_active',
          sortable: false,
        },
        { text: 'Действия', sortable: false, value: 'oid' },
      ],
    };
  },

  computed: {
    itemsSort() {
      return this.localItems.map((x, i) => {
        return { oid: x.oid, sort_order: i + 1 };
      });
    },

    editedName() {
      return this.editItem?.name;
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  watch: {
    items(v) {
      this.localItems = v;
    },

    itemsSort: {
      handler(v) {
        this.$store.dispatch(SET_ITEMS_SORT, v);
      },

      deep: true,
    },
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    saveForm() {
      this.$refs?.dashboardParentForm?.submit();
    },

    saveOrder(event) {
      const movedItem = this.localItems.splice(event.oldIndex, 1)[0];
      this.localItems.splice(event.newIndex, 0, movedItem);
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },
  },
};
</script>

<style lang="scss"></style>
