<template>
  <div class="modal-button">
    <a
      :title="title"
      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      @click.prevent="showModal = true"
    >
      <span class="svg-icon svg-icon-md svg-icon-primary">
        <inline-svg :src="icon"></inline-svg>
      </span>
    </a>

    <b-modal v-model="showModal" scrollable size="xl" @ok="sendOk">
      <template #modal-title>
        <h5>{{ title }}</h5>
      </template>
      <slot />
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Да </b-button>
        <b-button size="sm" variant="danger" @click="cancel()"> Нет </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => 'Подтвердите действие',
    },

    icon: {
      type: String,
      default: () => null,
    },

    buttonTitle: {
      type: String,
      default: () => null,
    },

    callbackParams: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    sendOk() {
      this.$emit('modal-ok', this.callbackParams);
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-button {
  display: inline-block;
}
</style>
