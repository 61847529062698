<template>
  <div
    class="d-flex justify-content-between align-items-center flex-wrap p-4 bg-white custom-header"
  >
    <div class="d-flex justify-content-start left-content">
      <slot name="left">
        <h1 style="margin-bottom: 0">{{ title }}</h1>
      </slot>
    </div>
    <div class="d-flex justify-content-end">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss">
.custom-header {
  .left-content {
    flex: 2;
  }
}
</style>