<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab :disabled="!isExists">
        <v-badge
          v-if="form.items?.length !== 0 && form.items !== null"
          :content="form.items?.length"
          color="red"
        >
          Дочерние блоки
        </v-badge>

        <span v-else> Дочерние блоки </span>
      </v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <!--<b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>-->
          <b-form>
            <b-form-group
              id="input-group-1"
              label="Родительский блок"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.ParentName"
                placeholder="Родительский блок"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-code"
              label="Код"
              label-for="input-code"
            >
              <b-form-input
                id="nput-code"
                v-model="form.Code"
                :state="validateState('Code')"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Название блока"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                :state="validateState('Name')"
                v-model="form.Name"
                placeholder="Название блока"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Текст для приложения"
              label-for="input-4"
            >
              <b-form-textarea
                v-model="form.Notes"
                id="input-20"
                :rows="5"
                size="lg"
                placeholder="Текст для приложения"
              />
            </b-form-group>

            <b-form-group
              id="input-group-type"
              label="Экшен"
              label-for="input-type"
            >
              <b-form-select
                v-model="form.ActionID"
                :options="localActionsOptions"
                id="input-type"
              />
            </b-form-group>

            <b-form-group label="Стиль" id="input-group-5">
              <b-form-input
                  id="input-5"
                  v-model="form.JSONStyle"
                  placeholder="Стиль"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Параметры"
              label-for="input-4"
            >
              <b-form-textarea
                v-model="form.JSONParams"
                id="input-20"
                :rows="5"
                size="lg"
                placeholder="Параметры"
              />
            </b-form-group>

            <b-form-group id="input-group-7">
              <switch-checkbox v-model="form.IsActive" label="Активен" />
            </b-form-group>

            <b-form-group
              id="input-group-23"
              label="Медиа"
              label-for="input-23"
            >
              <upload-file
                id="inpit-23"
                v-model="form.Media"
                :options="photoOptions"
              />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
      <v-tab-item>
        <in-publications-parent-list
          :items="form.items"
          :parent-form="form"
          :level="level + 1"
          @update-item="updateItem"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
import UploadFile from '@/view/content/widgets/FileDropSingle';
import { SAVE_ELEMENT_DETAIL } from '@/core/services/store/publications.module';

export default {
  components: { SwitchCheckbox, UploadFile },

  mixins: [validationMixin],

  name: 'PublicationsElementDetailForm',

  props: {
    value: {
      type: Object,
      default: () => null,
    },
    level: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      form: this.value,
      photoOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: 1,
        acceptedFiles: 'image/*',
      },
    };
  },

  computed: {
    ...mapGetters(['actionsOptions']),

    localActionsOptions() {
      const items = this.actionsOptions.map((x) => {
        return { text: x.text, value: x.value };
      });
      items.splice(0, 0, { text: 'Не  указано', value: null });
      return items;
    },

    isExists() {
      return Boolean(this?.form?.ID);
    },
  },

  validations: {
    form: {
      Name: {
        required,
      },
      Code: {
        required,
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },

    value(v) {
      this.form = v;
    },
  },

  methods: {
    prepareForm() {
      return;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async submit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      } else {
        this.prepareForm();
        const result = await this.$store.dispatch(
          SAVE_ELEMENT_DETAIL,
          this.form
        );

        if (result.error === null) {
          Swal.fire({
            title: '',
            text: this.isExists ? 'Сохранено!' : 'Создано!',
            icon: 'success',
            heightAuto: false,
          });
          return true;
        }
      }
      return false;
    },

    updateItem() {
      this.$emit('update-item');
    },
  },
};
</script>

<style lang="scss" scoped></style>
