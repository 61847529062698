import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import RealApiService from '@/core/services/realapi.service';
import MockService from '@/core/mock/mock.service';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { BooleanYesNo } from '@/core/filters';
import CustomHeader from '@/view/content/widgets/CustomHeader';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/formvalidation';
import '@/core/plugins/select2';
import '@/core/plugins/recursive-components';

Vue.filter('booleanYesNo', BooleanYesNo);
Vue.component('CustomHeader', CustomHeader);

// API service init
ApiService.init(store);
RealApiService.init(store);

// Remove this to disable mock API
MockService.init();

router.beforeEach((to, from, next) => {
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
