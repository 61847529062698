<template>
  <div style="height: 100%; display: flex;">
    <alert
      :show="alertShow"
      :type="alertType"
      show-close-button
      custom-class="layout-alert"
      @alert-close="closeAlert"
    >
      {{ alertMessage }}
    </alert>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { mapGetters } from 'vuex';
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { GET_INFO } from '@/core/services/store/references.module';
import { CHECK_TOKEN } from '@/core/services/store/auth.module';
import { SET_ALERT_SHOW } from '@/core/services/store/alert.module';
import Alert from './view/content/alerts/Alert';

export default {
  components: { Alert },
  name: 'MetronicVue',
  computed: {
    ...mapGetters(['isAuthenticated']),
    alertShow: {
      get() {
        return this.$store.state.alert.show;
      },

      set(v) {
        this.$store.commit(SET_ALERT_SHOW, v);
      },
    },

    alertMessage() {
      return this.$store.state.alert.message;
    },

    alertType() {
      return this.$store.state.alert.type;
    }
  },

  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    if (this.isAuthenticated) {
      this.$store.dispatch(CHECK_TOKEN);
      this.$store.dispatch(GET_INFO);
    } else {
      this.$router.replace({ name: 'login' });
    }
  },

  watch: {
    isAuthenticated(v) {
      if (!v) {
        this.$router.replace({ name: 'login' });
      }
    },
  },

  methods: {
    closeAlert() {
      this.alertShow = false;
    },
  }
};
</script>

<style lang="scss">
.layout-alert {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1500;
  background-color: #fff !important;
}
</style>
