import ApiService from '@/core/services/realapi.service';

// action types
export const GET_NOTIFICATION_BUTTONS = 'getNotificationButtons';
export const GET_NOTIFICATION_SCRIPTS = 'getNotificationScripts';
export const GET_NOTIFICATION_TEMPLATES = 'getNotificationTemplates';

export const GET_ITEM_NOTIFICATION_BUTTONS = 'getItemNotificationButtons';
export const GET_ITEM_NOTIFICATION_SCRIPTS = 'getItemNotificationScripts';
export const GET_ITEM_NOTIFICATION_TEMPLATES = 'getItemNotificationTemplates';

export const GET_DICT_NOTIFICATION_SCRIPTS = 'getDisctNotificationScripts';
export const GET_DICT_NOTIFICATION_BUTTONS = 'getDisctNotificationButtons';

export const SAVE_ITEM_NOTIFICATION_BUTTONS = 'saveItemNotificationButtons';
export const SAVE_ITEM_NOTIFICATION_SCRIPTS = 'saveItemNotificationScripts';
export const SAVE_ITEM_NOTIFICATION_TEMPLATES = 'saveItemNotificationTemplates';

export const SORT_NOTIFICATION_BUTTONS = 'sortNotificationButtons';

// mutation types
export const SET_NOTIFICATION_BUTTONS = 'setNotificationButtons';
export const SET_NOTIFICATION_SCRIPTS = 'setNotificationScripts';
export const SET_NOTIFICATION_TEMPLATES = 'setNotificationTemplates';

const state = {
  buttons: [],
  scripts: [],
  templates: [],
};

const actions = {
  [GET_NOTIFICATION_BUTTONS](context, filter = {}) {
    context.commit(SET_NOTIFICATION_BUTTONS, []);
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderButton/ReadDict', filter, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          context.commit(SET_NOTIFICATION_BUTTONS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_NOTIFICATION_SCRIPTS](context, filter = {}) {
    context.commit(SET_NOTIFICATION_SCRIPTS, []);
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderScript/ReadDict', filter, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          context.commit(SET_NOTIFICATION_SCRIPTS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_NOTIFICATION_TEMPLATES](context, filter = {}) {
    context.commit(SET_NOTIFICATION_TEMPLATES, []);
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderTemplate/ReadDict', filter, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          context.commit(SET_NOTIFICATION_TEMPLATES, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ITEM_NOTIFICATION_BUTTONS](context, OID = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ReminderButton/Read',
        { OID },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_ITEM_NOTIFICATION_SCRIPTS](context, OID = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ReminderScript/Read',
        { OID },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_ITEM_NOTIFICATION_TEMPLATES](context, OID = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ReminderTemplate/Read',
        { OID },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_DICT_NOTIFICATION_SCRIPTS]() {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderScript/ReadDict', {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve([]);
          reject(response);
        });
    });
  },

  [GET_DICT_NOTIFICATION_BUTTONS]() {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderButton/ReadDict', {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve([]);
          reject(response);
        });
    });
  },

  [SAVE_ITEM_NOTIFICATION_SCRIPTS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderScript/Save', data, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [SAVE_ITEM_NOTIFICATION_BUTTONS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderTemplate/SaveButton', data, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [SAVE_ITEM_NOTIFICATION_TEMPLATES](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ReminderTemplate/Save', data, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [SORT_NOTIFICATION_BUTTONS](context, sort = []) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ReminderTemplate/SaveOrderButton',
        { sort },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_NOTIFICATION_BUTTONS](state, items) {
    state.buttons = items;
  },

  [SET_NOTIFICATION_SCRIPTS](state, items) {
    state.scripts = items;
  },

  [SET_NOTIFICATION_TEMPLATES](state, items) {
    state.templates = items;
  },
};

const getters = {
  // actionsOptions(state) {
  //   return state.items.map((x) => {
  //     return { text: x.name, value: x.oid };
  //   });
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
