<template>
  <div class="create-block-buttons">
    <b-button variant="success" @click="modalShow = true"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание блока</h5>
      </template>

      <dashboard-child-form
        v-model="createItem"
        ref="dashboardCreateParentForm"
      />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Создать </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DashboardChildForm from '@/view/content/forms/dashboard/DashboardChildForm.vue';

export default {
  components: {
    DashboardChildForm,
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        name: '',
        action_oid: null,
        audience_oid: [],
        is_active: true,
        items: [],
        photo: null,
      },
    };
  },

  methods: {
    saveForm() {
      this.$refs?.dashboardCreateParentForm?.submit();
    },
  },
};
</script>

<style lang="scss" scoped></style>
