<template>
  <div class="create-block-buttons block-element-detail">
    <b-button variant="success" @click="modalShow = true"> Создать</b-button>
    <b-modal
      v-model="modalShow"
      scrollable
      size="xl"
      @ok="saveForm"
      @hide="initForm"
    >
      <template #modal-title>
        <h5>Создание блока</h5>
      </template>
      <publications-element-detail-form
        v-model="createItem"
        ref="publicationsCreateElementDetailForm"
        @saved="$emit('saved')"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PublicationsElementDetailForm from '@/view/content/forms/publications/PublicationsElementDetailForm.vue';

export default {
  components: {
    PublicationsElementDetailForm,
  },

  props: {
    parentForm: {
      type: Object,
      default: () => null,
    },

    withParent: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        Name: '',
        parent_name: this.parentForm.Name,
        Code: '',
        ActionID: null,
        is_active: true,
        items: [],
        Notes: '',
        photo: null,
        ParentID: this.withParent ? this.parentForm.ID : null,
        ElementID: this.parentForm?.ElementID
          ? this.parentForm?.ElementID
          : this.parentForm?.ID,
        ActionName: null,
        ActionPath: null,
        ActionJSONParams: null,
        SortOrder: 0,
      },
    };
  },

  methods: {
    async saveForm() {
      const result =
        await this.$refs?.publicationsCreateElementDetailForm?.submit();

      if (result) {
        this.$emit('saved', this.parentForm?.ID);
        this.modalShow = false;
      }
    },

    initForm() {
      this.createItem = {
        Name: '',
        parent_name: this.parentForm.Name,
        Code: '',
        action_oid: null,
        is_active: true,
        items: [],
        Notes: '',
        photo: null,
        ParentID: this.withParent ? this.parentForm.ID : null,
        ElementID: this.parentForm?.ElementID,
        ActionName: null,
        ActionPath: null,
        ActionJSONParams: null,
        SortOrder: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
