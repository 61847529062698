import ApiService from '@/core/services/realapi.service';
import JwtService from '@/core/services/jwt.service';
import { GET_INFO } from '@/core/services/store/references.module';

const TEMP_USER = {
  oid: '43242343242343200',
  firstname: 'Ivan',
  lastname: 'Petrov',
};

// action types
export const CHECK_TOKEN = 'checkToken';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const UPDATE_PASSWORD = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuth';
export const SET_PASSWORD = 'setPassword';
export const SET_ERROR = 'setError';
export const SET_USER = 'setUser';

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('Auth/GetToken', credentials)
        .then(({ data }) => {
          if (data.data !== null) {
            context.commit(SET_USER, TEMP_USER); // @@@TODO переделать на реальный потом когда появится метод
            JwtService.saveToken(data.data.access_token);
            context.commit(SET_AUTH, true);
            context.dispatch(GET_INFO);
          }
          resolve(data);
        })
        .catch(({ error }) => {
          context.commit(SET_ERROR, error?.message);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  [CHECK_TOKEN](context) {
    if (JwtService.getToken()) {
      ApiService.get('Auth/ValidToken')
        .then(() => {
          context.commit(SET_AUTH, true);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put('password', password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state) {
    state.isAuthenticated = true;
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },

  [SET_USER](state, user) {
    state.user = user;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
