// USA
export const locale = {
  TRANSLATOR: {
    SELECT: 'Выберите ваш язык',
  },
  MENU: {
    NEW: 'Новый',
    ACTIONS: 'Действия',
    CREATE_POST: 'Создать новый пост',
    PAGES: 'Страницы',
    FEATURES: 'Характеристики',
    APPS: 'Приложения',
    DASHBOARD: 'Дашбоард',
  },
  AUTH: {
    GENERAL: {
      OR: 'или',
      SUBMIT_BUTTON: 'Отправить',
      NO_ACCOUNT: 'У вас нет аккаунта?',
      SIGNUP_BUTTON: 'Зарегистрироваться',
      FORGOT_BUTTON: 'Забыли пароль',
      BACK_BUTTON: 'Назад',
      PRIVACY: 'Конфиденциальность',
      LEGAL: 'Legal',
      CONTACT: 'Контакт',
    },
    LOGIN: {
      TITLE: 'Вход в аккаунт',
      BUTTON: 'Войти',
    },
    FORGOT: {
      TITLE: 'Забыли пароль?',
      DESC: 'Введите Ваш электронный адрес, чтобы сбросить пароль',
      SUCCESS: 'Ваш аккаунт успешно сброшен.',
    },
    REGISTER: {
      TITLE: 'Зарегистрироваться',
      DESC: 'Введите свои данные, чтобы создать учетную запись',
      SUCCESS: 'Ваш аккаунт успешно зарегистрирован.',
    },
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Полное имя',
      PASSWORD: 'Пароль',
      CONFIRM_PASSWORD: 'Подтвержение пароля',
      USERNAME: 'Логин',
    },
    VALIDATION: {
      INVALID: '{{name}} неверное',
      REQUIRED: '{{name}} обязательно',
      MIN_LENGTH: '{{name}} минимальная длина {{min}}',
      AGREEMENT_REQUIRED: 'Необходимо принять условия',
      NOT_FOUND: 'Запрошенный {{name}} не найден',
      INVALID_LOGIN: 'Данные для входа неверны',
      REQUIRED_FIELD: 'Обязательное поле',
      MIN_LENGTH_FIELD: 'Минимальная длина поля:',
      MAX_LENGTH_FIELD: 'Максимальная длина поля:',
      INVALID_FIELD: 'Недопустимое поле',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Количество выбранных записей: ',
      ALL: 'Все',
      SUSPENDED: 'Приостановлено',
      ACTIVE: 'Активный',
      FILTER: 'Фильтр',
      BY_STATUS: 'по статусу',
      BY_TYPE: 'по типу',
      BUSINESS: 'Бизнес',
      INDIVIDUAL: 'Индивидуальный',
      SEARCH: 'Поиск',
      IN_ALL_FIELDS: 'во всех полях',
    },
    ECOMMERCE: 'электронная коммерция',
    CUSTOMERS: {
      CUSTOMERS: 'Клиенты',
      CUSTOMERS_LIST: 'Список клиентов',
      NEW_CUSTOMER: 'Новый клиент',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Удалить клиента',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Клиент удаляется...',
        MESSAGE: 'Клиент был удален',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Удалить клиентов',
        DESCRIPTION: 'Вы уверены, что навсегда удалите выбранных клиентов?',
        WAIT_DESCRIPTION: 'Клиенты удаляются...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Статус был обновлен для выбранных клиентов',
        MESSAGE: 'Статус выбранных клиентов успешно обновлен',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Клиент был обновлен',
        ADD_MESSAGE: 'Клиент создан',
      },
    },
  },
};
