import Vue from 'vue';
import axios from 'axios';
import JwtService from '@/core/services/jwt.service';
import { SHOW_ALERT, HIDE_ALERT } from '@/core/services/store/alert.module';
import router from '@/router.js';
// import store from "@/core/services/store";
// import { PURGE_AUTH } from "@/core/services/store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init(store) {
    const realaxios = axios.create();
    Vue.realaxios = realaxios;
    Vue.realaxios.defaults.baseURL = `${process.env.VUE_APP_B2B_URL}`;

    Vue.realaxios.interceptors.request.use(
      function (config) {
        store.dispatch(HIDE_ALERT);
        config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    Vue.realaxios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.data.data === null && response.data.error !== null) {
          store.dispatch(SHOW_ALERT, {
            type: 'danger',
            message: response.data.error?.message,
          });

          setTimeout(() => {
            store.dispatch(HIDE_ALERT);
          }, 5000);
        }
        return response;
      },
      function (error) {
        const status = parseInt(error.response.status);
        switch (status) {
          case 401:
            router.replace({ name: 'login' });
            JwtService.destroyToken();
            break;
        }
        return Promise.reject(error);
      }
    );

    /* if (process.env.NODE_ENV === 'production') {
      Vue.axios.defaults.baseURL = 'https://b2b.ma.ru/';
    } */
  },

  query(resource, params) {
    try {
      return Vue.realaxios.get(resource, { params });
    } catch (err) {
      return new Error(err);
    }
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    try {
      return Vue.realaxios.get(`${resource}/${slug}`);
    } catch (err) {
      return new Error(err);
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = {}) {
    try {
      return Vue.realaxios.post(`${resource}`, params, config);
    } catch (err) {
      return new Error(err);
    }
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    try {
      return Vue.realaxios.put(`${resource}/${slug}`, params);
    } catch (err) {
      return new Error(err);
    }
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    try {
      return Vue.realaxios.put(`${resource}`, params);
    } catch (err) {
      return new Error(err);
    }
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug = '') {
    try {
      return Vue.realaxios.delete(`${resource}/${slug}`);
    } catch (err) {
      return new Error(err);
    }
  },
};

export default ApiService;
