import Vue from 'vue';

import DashboardList from '@/view/content/lists/DashboardList.vue';
import DashboardParentList from '@/view/content/lists/DashboardParentList.vue';
import DashboardChildForm from '@/view/content/forms/dashboard/DashboardChildForm.vue';
import DashboardParentForm from '@/view/content/forms/dashboard/DashboardParentForm.vue';

import InPublicationsList from '@/view/content/lists/InPublicationsList.vue';
import InPublicationsParentList from '@/view/content/lists/InPublicationsParentList.vue';
import PublicationsElementDetailForm from '@/view/content/forms/publications/PublicationsElementDetailForm.vue';
import PublicationsParentForm from '@/view/content/forms/publications/PublicationsParentForm.vue';

Vue.component('dashboard-list', DashboardList);
Vue.component('dashboard-parent-list', DashboardParentList);
Vue.component('dashboard-child-form', DashboardChildForm);
Vue.component('dashboard-parent-form', DashboardParentForm);

Vue.component('in-publications-list', InPublicationsList);
Vue.component('in-publications-parent-list', InPublicationsParentList);
Vue.component(
  'publications-element-detail-form',
  PublicationsElementDetailForm
);
Vue.component('publications-parent-form', PublicationsParentForm);
