<template>
  <div class="auto-dealer-table">
    <custom-header>
      <template #left>
        <v-text-field
          v-model="search"
          label="Поиск..."
          class="mx-4"
        ></v-text-field>
      </template>
      <create-element-detail-button
        :parent-form="parentForm"
        :with-parent="level > 2"
        @saved="savedNew"
      />
    </custom-header>

    <v-data-table
      v-if="items !== null"
      v-sortable-data-table
      :headers="headers"
      :items="localItems"
      item-key="ID"
      :item-class="itemClass"
      :search="search"
      :custom-filter="searchFilter"
      class="elevation-1 row-links"
      hide-default-footer
      disable-pagination
      @sorted="saveOrder"
    >
      <template #item.IsActive="{ item }">
        <yes-no-badge :active="item.IsActive" />
      </template>

      <template #item.ID="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.ID } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.ID)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary in-detail">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>

        <!--<modal-button
          :callback-params="{ oid: item.ID }"
          icon="/media/svg/icons/General/Trash.svg"
          @modal-ok="deleteItem"
        >
          Вы уверены, что хотите удалить этот блок?
        </modal-button>-->
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <publications-element-detail-form
        v-model="editItem"
        :level="level + 1"
        ref="publicationsElementDetailForm"
        @saved="$emit('update-item')"
      />

      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="success"
          @click="saveForm"
        >
          Сохранить
        </b-button>
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  GET_ELEMENT_DETAIL,
  DELETE_ITEM,
  SORT_ELEMENT_DETAIL
} from '@/core/services/store/publications.module';
import Sortable from 'sortablejs';
import CreateElementDetailButton from '../widgets/publications/CreateElementDetailButton.vue';
/* import ModalButton from '../widgets/ModalButton.vue'; */
import YesNoBadge from '../YesNoBadge.vue';

export default {
  components: { CreateElementDetailButton, /* ModalButton , */ YesNoBadge },

  name: 'InPublicationsParentList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },

    parentOid: {
      type: [String, Number],
      default: () => null,
    },

    parentForm: {
      type: Object,
      default: null,
    },

    level: {
      type: Number,
      default: () => 0,
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          filter: '.filtered',
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      localItems: this.items,
      buttonLoading: false,
      sortAllow: false,
      shadowItems: this.items,
      search: '',
      headers: [
        { text: 'Название', value: 'Name', sortable: false },
        {
          text: ' Активен',
          value: 'IsActive',
          sortable: false,
        },
        { text: 'Действия', sortable: false, value: 'ID' },
      ],
    };
  },

  computed: {
    itemsSort() {
      if (Array.isArray(this.localItems)) {
        return this.localItems.map((x, i) => {
          return { ID: x.ID, SortOrder: i + 1 };
        });
      }
      return [];
    },

    editedName() {
      return this.editItem?.name;
    },

    parentID() {
      if (parseInt(this.$route.params.id) === parseInt(this.parentOid)) {
        return null;
      }

      return parseInt(this.parentOid);
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', async () => {
      const oid = this.editItem?.ID;
      if (oid) {
        this.editItem = await this.$store.dispatch(GET_ELEMENT_DETAIL, oid);
      }
    });

    setTimeout(this.setAllowSort, 3000);
  },

  watch: {
    items(v) {
      this.localItems = v;
    },

    itemsSort: {
      handler(v) {
        if (v?.length !== 0 && this.sortAllow) {
          this.$store.dispatch(SORT_ELEMENT_DETAIL, v);
        }
      },

      deep: true,
    },
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ELEMENT_DETAIL, oid);
      this.modalShow = true;
      return false;
    },

    async saveForm() {
      this.buttonLoading = true;
      const result = await this.$refs?.publicationsElementDetailForm?.submit();
      this.buttonLoading = false;
      if (result) {
        this.$emit('saved');
        this.modalShow = false;
      }
    },

    saveOrder(event) {
      const movedItem = this.localItems.splice(event.oldIndex, 1)[0];
      this.localItems.splice(event.newIndex, 0, movedItem);
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },

    searchFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        item.Name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },

    async savedNew() {
      this.$emit('update-item');
    },

    async deleteItem(params) {
      await this.$store.dispatch(DELETE_ITEM, params.oid);
    },

    setAllowSort() {
      this.sortAllow = true;
    },

    setCurrentItems(items) {
      this.localItems = items;
    },
  },
};
</script>

<style lang="scss"></style>
