import Vue from 'vue';

import responses from './responses';

var MockAdapter = require('axios-mock-adapter');

// mock testing user accounts
const users = [
  {
    oid: 43242343242343200,
    firstname: 'Ivan',
    lastname: 'Petrov',
    email: 'admin@demo.com',
    password: 'demo',
    token: 'B2EA76D10C5F22489208F5376688BF1E',
  },
];

const MockService = {
  init() {
    // this sets the mock adapter on the default instance
    var mock = new MockAdapter(Vue.axios);

    // mock login request
    mock.onPost('/login').reply((data) => {
      const credential = JSON.parse(data.data);
      const found = users.find((user) => {
        return (
          credential.email === user.email &&
          credential.password === user.password
        );
      });
      if (found) {
        const response = {
          data: found,
          dataVersion: null,
          requestID: '6f4333ff-6cd1-442f-8a53-27e1d87c9b6f',
          error: null,
        };
        return [200, response];
      }
      return [404, { errors: ['The login detail is incorrect'] }];
    });

    // mock to verify authentication
    mock.onGet(/\/user\/?/).reply((data) => {
      const token = data.headers.Authorization.replace('Bearer ', '');
      if (token !== 'undefined') {
        const found = users.find((user) => {
          return token === user.token;
        });
        if (found) {
          return [200, responses.user];
        }
      }
      return [401, { errors: ['Invalid authentication'] }];
    });

    mock.onGet(/\/references\/?/).reply(() => {
      return [200, responses.references];
    });

    mock.onGet(/\/error\/?/).reply((data) => {
      const code = data.url.split('/')[1];
      return [code, 'Error code' + code];
    });

    mock.onGet(/\/dealers\/\d+/).reply(() => {
      return [200, responses.dealers.detail];
    });

    mock.onGet(/\/dealers/).reply(() => {
      return [200, responses.dealers.list];
    });

    mock.onGet(/\/export-settings\/\d+/).reply(() => {
      return [200, responses.exportSettings.detail];
    });

    mock.onGet(/\/export-settings/).reply(() => {
      return [200, responses.exportSettings.list];
    });

    mock.onGet(/\/brands\/\d+/).reply(() => {
      return [200, responses.brands.detail];
    });

    mock.onGet(/\/brands/).reply(() => {
      return [200, responses.brands.list];
    });

    mock.onGet(/\/model-groups\/\d+/).reply(() => {
      return [200, responses.modelGroups.detail];
    });

    mock.onGet(/\/model-groups/).reply(() => {
      return [200, responses.modelGroups.list];
    });

    mock.onGet(/\/model-photos\/\d+/).reply(() => {
      return [200, responses.modelPhotos.detail];
    });

    mock.onGet(/\/model-photos/).reply(() => {
      return [200, responses.modelPhotos.list];
    });

    mock.onGet(/\/models\/\d+\/equipmentsc/).reply(() => {
      return [200, responses.modelEquipments.listc];
    });

    mock.onGet(/\/models\/\d+\/equipments/).reply(() => {
      return [200, responses.modelEquipments.list];
    });

    mock.onGet(/\/models\/\d+/).reply(() => {
      return [200, responses.models.detail];
    });

    mock.onPut(/\/models\/\d+/).reply(() => {
      return [200, responses.models.detail];
    });

    mock.onGet(/\/models/).reply(() => {
      return [200, responses.models.list];
    });

    mock.onGet(/\/model-equipments\//).reply(() => {
      return [200, responses.modelEquipments.list];
    });

    mock.onGet(/\/actions\/\d+/).reply(() => {
      return [200, responses.actions.detail];
    });

    mock.onGet(/\/actions/).reply(() => {
      return [200, responses.actions.list];
    });

    mock.onGet(/\/dashboard\/child\/\d+/).reply(() => {
      return [200, responses.dashboard.childBlock];
    });

    mock.onGet(/\/dashboard\/\d+/).reply(() => {
      return [200, responses.dashboard.typeBlock];
    });

    mock.onDelete(/\/dashboard\/\d+/).reply(() => {
      return [204, responses.dashboard.typeBlock];
    });

    mock.onGet(/\/dashboard/).reply(() => {
      return [200, responses.dashboard.list];
    });

    mock.onGet(/\/publications\/\d+\/\d+\/child\/d+/).reply(() => {
      return [200, responses.publications.childBlock];
    });

    mock.onGet(/\/publications\/\d+\/\d+/).reply(() => {
      return [200, responses.publications.typeBlock];
    });

    mock.onGet(/\/publications\/\d+/).reply(() => {
      return [200, responses.dashboard.list];
    });

    mock.onGet(/\/publications/).reply(() => {
      return [200, responses.publications.list];
    });
  },
};

export default MockService;
