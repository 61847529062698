import ApiService from '@/core/services/api.service';

// action types
export const GET_ITEMS = 'getItemsExportSettings';
export const GET_ITEM = 'getItemExportSettings';
export const UPDATE_ITEM = 'updateItemExportSettings';
export const DELETE_ITEM = 'deleteItemExportSettings';

// mutation types
export const SET_ITEMS = 'setItemsExportSettings';
export const SET_ITEM_NULL = 'setItemNullExportSettings';

const PREFIX = 'export-settings';
const state = {
  items: [],
  item: null,
};

const actions = {
  [GET_ITEMS](context, filter = {}) {
    context.commit(SET_ITEMS, []);
    return new Promise((resolve, reject) => {
      ApiService.query(PREFIX, filter)
        .then(({ data }) => {
          context.commit(SET_ITEMS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ITEM](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.get(PREFIX, id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [UPDATE_ITEM](context, { id, data }) {
    return new Promise((resolve, reject) => {
      ApiService.update(PREFIX, id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [DELETE_ITEM](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.delete(PREFIX, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ITEMS](state, items) {
    state.items = items;
  },
};

export default {
  state,
  actions,
  mutations,
};
