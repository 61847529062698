<template>
  <div
    v-show="show"
    :class="[`alert-outline-${type}`, customClass]"
    class="alert alert-custom alert-outline-2x fade show mb-5"
    role="alert"
  >
    <div class="alert-icon"><i class="flaticon-warning"></i></div>
    <div class="alert-text"><slot /></div>
    <div class="alert-close">
      <button
        v-show="showCloseButton"
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="$emit('alert-close')"
      >
        <span aria-hidden="true"><i class="ki ki-close"></i></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'primary',
    },

    show: {
      type: Boolean,
      default: () => false,
    },

    showCloseButton: {
      type: Boolean,
      default: () => true,
    },

    customClass: {
      type: String,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
