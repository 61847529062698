import references from './refernces.json';
import user from './user.json';
import dealers from './dealers.json';
import exportSettings from './export-settings.json';
import brands from './brands.json';
import modelGroups from './model-groups.json';
import modelPhotos from './model-photos.json';
import models from './models.json';
import modelEquipments from './model-equipments.json';
import dashboard from './dashboard.json';
import actions from './actions.json';
import publications from './publications.json';

export default {
  brands,
  dealers,
  references,
  user,
  exportSettings,
  modelGroups,
  modelPhotos,
  models,
  modelEquipments,
  dashboard,
  actions,
  publications,
};
