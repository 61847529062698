<template>
  <span
    :class="{ 'label-light-success': active, 'label-light-danger': !active }"
    class="label label-lg label-inline"
  >
    {{ active | booleanYesNo }}
  </span>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped>
.yes-no-badge {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
