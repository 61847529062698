import ApiService from '@/core/services/realapi.service';

// action types
export const GET_ITEMS_PUBLICATIONS = 'getItemsPublication';
export const GET_ITEM_PUBLICATIONS = 'getItemPublication';

export const SAVE_ELEMENT_GROUP = 'saveElementGroup';
export const SAVE_ELEMENT = 'saveElement';

export const GET_ELEMENT_DETAIL = 'getElementDetail';
export const SAVE_ELEMENT_DETAIL = 'saveElementDetail';

export const GET_ITEMS = 'getItemsInPublications';
export const GET_ITEM = 'getItemPublications';
export const GET_ELEMENT_GROUP = 'getElementGroup';
export const GET_ITEM_CHILD = 'getItemChildPublications';
export const UPDATE_ITEM = 'updateItemPublications';
export const DELETE_ITEM = 'deleteItemPublications';

export const SORT_ELEMENT = 'sortElement';
export const SORT_ELEMENT_DETAIL = 'sortElementDetail';

// mutation types
export const SET_ITEMS_PUBLICATIONS = 'setItemsPublications';

export const SET_ITEMS = 'setItemsInPublication';
export const SET_ITEM_NULL = 'setItemNullPublications';

const state = {
  publicationsItems: [],
  items: [],
  item: null,
};

const actions = {
  [GET_ITEMS_PUBLICATIONS](context) {
    context.commit(SET_ITEMS_PUBLICATIONS, []);
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ElementGroup/ReadDict',
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          context.commit(SET_ITEMS_PUBLICATIONS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ELEMENT_GROUP](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ElementGroup/Read',
        { ID: id },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [SAVE_ELEMENT_GROUP](context, form = {}) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ElementGroup/Save', form, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          context.dispatch(GET_ITEMS_PUBLICATIONS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [SAVE_ELEMENT](context, form = {}) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/Element/Save', form, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ITEMS](context, id) {
    context.commit(SET_ITEMS, []);
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/Element/ReadByGroup',
        { GroupID: id },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          context.commit(SET_ITEMS, data.data.Items);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ITEM](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/Element/Read',
        { ID: id },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_ELEMENT_DETAIL](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ElementDetail/Read',
        { ID: id },
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [SAVE_ELEMENT_DETAIL](context, form = {}) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MAWA1/ElementDetail/Save', form, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [SORT_ELEMENT](context, sort = []) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/Element/SaveOrder',
        { sort },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
        .then(({ data }) => {
          context.dispatch(GET_ITEMS_PUBLICATIONS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [SORT_ELEMENT_DETAIL](context, sort = []) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        '/MASP/MAWA1/ElementDetail/SaveOrder',
        { sort },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
        .then(({ data }) => {
          context.dispatch(GET_ITEMS_PUBLICATIONS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  /*[GET_ITEM_PUBLICATIONS](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.post(PREFIX, { GroupID: id })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_ITEMS](context, params = {id: null, filter: {} }) {
    context.commit(SET_ITEMS, []);
    return new Promise((resolve, reject) => {
      ApiService.query(`${PREFIX}/${params.id}`, params.filter)
        .then(({ data }) => {
          context.commit(SET_ITEMS, data.data.items);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ITEM](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.get(PREFIX, id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [GET_ITEM_CHILD](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${PREFIX}/child`, id)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          resolve({});
          reject(response);
        });
    });
  },

  [UPDATE_ITEM](context, { id, data }) {
    return new Promise((resolve, reject) => {
      ApiService.update(PREFIX, id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [DELETE_ITEM](context, id = null) {
    return new Promise((resolve, reject) => {
      ApiService.delete(PREFIX, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },*/
};

const mutations = {
  [SET_ITEMS_PUBLICATIONS](state, items) {
    state.publicationsItems = items;
  },

  [SET_ITEMS](state, items) {
    state.items = items;
  },
};

export default {
  state,
  actions,
  mutations,
};
