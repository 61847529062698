import ApiService from '@/core/services/realapi.service';

// action types
export const GET_UPLOAD_TOKEN = 'getUploadToken';
export const GET_UPLOAD_TOKEN_MS = 'getUploadTokenMS';
export const UPLOAD = 'uploadFiles';
export const UPLOAD_SINGLE_FILE = 'uploadSingleFile';
export const UPLOAD_SINGLE_FILE_MS = 'uploadSingleFileMS';
export const DELETE_ITEM = 'deleteItemBrands';

// mutation types
export const SET_UPLOAD_TOKEN = 'setUploadToken';
export const SET_UPLOAD_TOKEN_MS = 'setUploadTokenMS';

const state = {
  token: null,
  tokenMS: null,
  item: null,
};

const actions = {
  [GET_UPLOAD_TOKEN](context) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MSV2/File/BucketToken', {
        bucket: process.env.VUE_APP_FILE_UPLOAD_BUCKET,
      })
        .then(({ data }) => {
          context.commit(SET_UPLOAD_TOKEN, data.data.token);
          resolve(data.data.token);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_UPLOAD_TOKEN_MS](context) {
    return new Promise((resolve, reject) => {
      ApiService.post('/MASP/MSV2/File/BucketToken', {
        bucket: process.env.VUE_APP_FILE_UPLOAD_BUCKET_MS,
      })
        .then(({ data }) => {
          context.commit(SET_UPLOAD_TOKEN_MS, data.data.token);
          resolve(data.data.token);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [UPLOAD](context, files) {
    if (Array.isArray(files)) {
      // @@TODO
    } else {
      // @@TODO
    }
  },

  [UPLOAD_SINGLE_FILE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `${process.env.VUE_APP_FILE_UPLOAD_URL}/${process.env.VUE_APP_FILE_UPLOAD_BUCKET}/${context.state.token}`,
        data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [UPLOAD_SINGLE_FILE_MS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `${process.env.VUE_APP_FILE_UPLOAD_URL}/${process.env.VUE_APP_FILE_UPLOAD_BUCKET_MS}/${context.state.tokenMS}`,
        data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_UPLOAD_TOKEN](state, token) {
    state.token = token;
  },

  [SET_UPLOAD_TOKEN_MS](state, token) {
    state.tokenMS = token;
  },
};

export default {
  state,
  actions,
  mutations,
};
