import ApiService from '@/core/services/realapi.service';

// action types
export const GET_INFO = 'getInfo';

// mutation types
export const SET_INFO = 'setInfo';

const PREFIX = '/MASP/MAWA1/Refs/ReadDict';
const state = {
  info: null,
};

const actions = {
  [GET_INFO](context) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        PREFIX,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )
        .then(({ data }) => {
          context.commit(SET_INFO, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_INFO](state, info) {
    state.info = info;
  },
};

const getters = {
  locksmithServices() {
    return state.info?.locksmith_service || [];
  },

  bodyShops() {
    return state.info?.body_shop || [];
  },

  brands() {
    return state.info?.brand_oid || [];
  },

  groups() {
    return state.info?.group_id || [];
  },

  routes() {
    return state.info?.route_oid || [];
  },

  audiences() {
    return state.info?.VisibilityTypeID || [];
  },

  typeBlocks() {
    return state.info?.ElementTypeID || [];
  },

  actionsOptions(state) {
    return state.info?.ActionID?.map((x) => {
      return { text: x.Name, value: x.ID };
    });
  },

  reminderTypesOptions(state) {
    return state.info?.ReminderTypeID?.map((x) => {
      return { text: x.Name, value: x.ID };
    });
  },

  actionPathsOptions(state) {
    return state.info?.ActionPathID?.map((x) => {
      return { text: x.Name, value: x.ID };
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
