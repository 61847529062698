<template>
  <div class="auto-dealer-table">
    <custom-header>
      <template #left>
        <v-text-field
          v-model="search"
          label="Поиск..."
          class="mx-4"
        ></v-text-field>
      </template>
      <create-child-block-button />
    </custom-header>

    <v-data-table
      v-sortable-data-table
      :headers="headers"
      :items="localItems"
      item-key="name"
      :item-class="itemClass"
      :search="search"
      :custom-filter="searchFilter"
      disable-pagination
      class="elevation-1 row-links"
      hide-default-footer
      @sorted="saveOrder"
    >
      <template #item.is_active="{ item }">
        <switch-checkbox v-model="item.is_active" />
      </template>

      <template #item.oid="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.oid } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.oid)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>

        <modal-button
          :callback-params="{ oid: item.oid, parentOid: parentOid }"
          icon="/media/svg/icons/General/Trash.svg"
          @modal-ok="deleteItem"
        >
          Вы уверены, что хотите удалить этот блок?
        </modal-button>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <dashboard-child-form v-model="editItem" ref="dashboardChildForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  SET_ITEMS_SORT,
  GET_ITEM_CHILD,
  DELETE_ITEM,
} from '@/core/services/store/dashboard.module';
import Sortable from 'sortablejs';
import CreateChildBlockButton from '../widgets/dashboard/CreateChildBlockButton.vue';
import SwitchCheckbox from '@/view/content/SwitchCheckbox.vue';
import ModalButton from '../widgets/ModalButton.vue';

export default {
  components: { CreateChildBlockButton, SwitchCheckbox, ModalButton },

  name: 'DashboardParentList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },

    parentOid: {
      type: [String, Number],
      default: () => null,
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          filter: '.filtered',
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      localItems: this.items,
      search: '',
      headers: [
        { text: 'Название', value: 'name', sortable: false },
        {
          text: ' Активен',
          value: 'is_active',
          sortable: false,
        },
        { text: 'Действия', sortable: false, value: 'oid' },
      ],
    };
  },

  computed: {
    itemsSort() {
      return this.localItems.map((x, i) => {
        return { oid: x.oid, sort_order: i + 1 };
      });
    },

    editedName() {
      return this.editItem?.name;
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', async () => {
      const oid = this.editItem?.oid;
      if (oid) {
        this.editItem = await this.$store.dispatch(GET_ITEM_CHILD, oid);
      }
    });
  },

  watch: {
    items(v) {
      this.localItems = v;
    },

    itemsSort: {
      handler(v) {
        this.$store.dispatch(SET_ITEMS_SORT, v);
      },

      deep: true,
    },
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM_CHILD, oid);
      this.modalShow = true;
      return false;
    },

    saveForm() {
      this.$refs?.dashboardChildForm?.submit();
    },

    saveOrder(event) {
      const movedItem = this.localItems.splice(event.oldIndex, 1)[0];
      this.localItems.splice(event.newIndex, 0, movedItem);
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },

    searchFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        item.Name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },

    async deleteItem(params) {
      await this.$store.dispatch(DELETE_ITEM, params.oid);
    },
  },
};
</script>

<style lang="scss"></style>
