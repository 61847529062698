<template>
  <div class="custom-control custom-switch custom-switch-lg">
    <input
      v-model="localValue"
      type="checkbox"
      class="custom-control-input"
      :id="id"
      :checked="localValue"
    />
    <label
      class="custom-control-label"
      for="is-salon-for-web-checkbox"
      @click="click"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
import uniq from 'uuid';
export default {
  props: {
    id: {
      type: String,
      default: () => 'checkbox-' + uniq(),
    },

    value: {
      type: [Boolean, String],
      default: false,
    },

    label: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      localValue: this.value,
    };
  },

  watch: {
    localValue() {
      this.$emit('input', this.localValue);
    },

    value(v) {
      this.localValue = v;
    },
  },

  methods: {
    click() {
      this.localValue = !this.localValue;
    },
  },
};
</script>

<style lang="scss"></style>
