// actions types
export const SHOW_ALERT = 'showAlert';
export const HIDE_ALERT = 'hideAlert';

// mutation types
export const SET_ALERT_MESSAGE = 'setAlertMessage';
export const SET_ALERT_SHOW = 'setAlertShow';
export const SET_ALERT_TYPE = 'setAlertType';

const state = {
  message: null,
  show: false,
  type: 'primary',
};

const actions = {
  [SHOW_ALERT](context, { message, type }) {
    context.commit(SET_ALERT_MESSAGE, message);
    context.commit(SET_ALERT_TYPE, type);
    context.commit(SET_ALERT_SHOW, true);
  },

  [HIDE_ALERT](context) {
    context.commit(SET_ALERT_MESSAGE, null);
    context.commit(SET_ALERT_TYPE, 'primary');
    context.commit(SET_ALERT_SHOW, false);
  },
};

const mutations = {
  [SET_ALERT_MESSAGE](state, message) {
    state.message = message;
  },

  [SET_ALERT_SHOW](state, value) {
    state.show = value;
  },

  [SET_ALERT_TYPE](state, value) {
    state.type = value;
  },
};

export default {
  state,
  actions,
  mutations,
};
